import styles from './Carrusel.module.css';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';

export const Carrusel = () => {
  return (
    <Carousel>
      <Carousel.Item>
        <img className={styles.carouselitem} src='img/Chubut3.jpg' alt='First slide' />
      </Carousel.Item>
      <Carousel.Item>
        <img className={styles.carouselitem} src='img/Chubut2.jpg' alt='Second slide' />
      </Carousel.Item>
      <Carousel.Item>
        <img className={styles.carouselitem} src='img/Chubut1.jpg' alt='Third slide' />
      </Carousel.Item>
    </Carousel>
  );
};
