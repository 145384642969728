import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Boton } from '../botones/Botones';
import styles from './Footer.module.css';
import { faInstagram, faTwitter, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { Container } from '@mui/material';

export const Footer = () => {
  return (
    <Container maxWidth='xl'>
      <div className={styles.footercontainer}>
        <Boton href='https://www.instagram.com/apissys_chubut/'>
          <FontAwesomeIcon className='icons' icon={faInstagram}></FontAwesomeIcon>
        </Boton>
        <Boton href='https://www.facebook.com/ApissysChubut'>
          <FontAwesomeIcon className='icons' icon={faFacebook}></FontAwesomeIcon>
        </Boton>
        <Boton href='https://twitter.com/apissys_chubut'>
          <FontAwesomeIcon className='icons' icon={faTwitter}></FontAwesomeIcon>
        </Boton>
      </div>
    </Container>
  );
};
