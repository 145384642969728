import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress, Divider } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useArticles } from '../../hooks/useArticles/useArticles';
import { useDocuments } from '../../hooks/useDocuments/useDocuments';
import { Footer } from '../footer/Footer';
import { Nav } from '../nav/Nav';
import { RichTextEditor } from '../richTextRenderer/RichTextEditor';
import { Seccion } from '../seccion/Seccion';
import styles from './Articulo.module.css';
import styled from '@emotion/styled';

const CenteredContent = styled.article`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
`;

export const Articulo = () => {
  const { newsId } = useParams();
  const { getPublicUrl, getTitle, getDescription } = useDocuments();
  const { get, deserialize } = useArticles();
  const [article, setArticle] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const dateFormatter = new Intl.DateTimeFormat('es', { day: 'numeric', month: 'long', year: 'numeric' });

  useEffect(() => {
    if (newsId) {
      setIsLoading(true);
      get(newsId)
        .then(({ data: a }) => {
          setArticle(a);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);

  if (isLoading) {
    return (
      <CenteredContent>
        <CircularProgress />
      </CenteredContent>
    );
  } else if (!isLoading && !article) {
    return (
      <CenteredContent>
        <img
          className='logoytexto'
          src={process.env.PUBLIC_URL + '/img/logoytextoapissys.svg'}
          alt='logoytextoapissys'
        />
        <span>Ha ocurrido un error inesperado y no se encuentra su articulo.</span>
        <a href='/'>Volver a inicio</a>
      </CenteredContent>
    );
  } else if (article) {
    return (
      <>
        <Nav />
        <Seccion sx={{ marginTop: '2rem' }}>
          <header>
            <p className={styles['category-name']}>{article.category.name?.es}</p>
            <h1 className={styles['title']}>{article.name?.es}</h1>
            <p className={styles['intro']}>{article.intro?.es}</p>
            <p className={styles['date-time']}>{dateFormatter.format(new Date(article.createdAt))}</p>
            <p>{article.ca}</p>
          </header>
          <Divider style={{ borderColor: '#444', margin: '1rem 0' }} />
          <div key={article._id} title={article.name?.es}>
            <div className={styles.mainImage}>
              <div className={styles.imageContainer}>
                <img
                  className={styles.image}
                  src={getPublicUrl(article.portrait ?? article.mainImage)}
                  alt={`${getTitle(article.portrait ?? article.mainImage)} - ${getDescription(
                    article.portrait ?? article.mainImage
                  )}`}
                />
              </div>
              <p className={styles['mainImage__description']}>
                {(article.portrait ?? article.mainImage).description?.es}
              </p>
            </div>
            <article dangerouslySetInnerHTML={{ __html: deserialize(article.text?.es ?? 'null') }}>
            </article>
          </div>
        </Seccion>
        <Footer />
      </>
    );
  }
  return <></>;
};
