import { useEffect, useReducer, useState } from 'react';
import styles from './Formulario.module.css';
import { Grid, TextField, Button, Card, CardContent } from '@material-ui/core';
import { useContact } from '../../hooks/useContact/useContact';
import { Alert } from '@mui/material';

export const Formulario = ({ children }: any) => {
  const [form, setForm] = useReducer((state: any, newValues: any) => ({ ...state, ...newValues }), {});
  const { create } = useContact();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState<null | boolean>(null);

  const submitForm = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    setIsLoading(true);
    create(form)
      .then(() => {
        setIsSuccess(true);
        setForm({ name: '', email: '', comment: '' });
      })
      .catch(() => setIsSuccess(false))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (isSuccess !== null) {
      setTimeout(() => setIsSuccess(null), 10000);
    }
  }, [isSuccess]);

  return (
    <div className='App'>
      <Grid>
        <Card className={styles.card1} elevation={0}>
          <CardContent>
            <h2 className='contactanosTitle'>Contactanos</h2>
            <form onSubmit={submitForm}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    placeholder='Nombre y Apellido'
                    label='Nombre y Apellido'
                    variant='outlined'
                    fullWidth
                    required
                    value={form.name}
                    onChange={(v) => setForm({ name: v.currentTarget.value })}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type='email'
                    placeholder='Email'
                    label='Correo electrónico'
                    variant='outlined'
                    fullWidth
                    required
                    value={form.email}
                    onChange={(v) => setForm({ email: v.currentTarget.value })}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label='Mensaje'
                    multiline
                    rows={4}
                    placeholder='Tu pregunta'
                    variant='outlined'
                    fullWidth
                    required
                    value={form.comment}
                    onChange={(v) => setForm({ comment: v.currentTarget.value })}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button type='submit' variant='contained' color='primary' disabled={isLoading}>
                    Enviar
                  </Button>
                </Grid>
                {isSuccess ? (
                  <Grid item xs={12}>
                    <Alert severity='success'>Email enviado con exito!</Alert>
                  </Grid>
                ) : isSuccess === false ? (
                  <Grid item xs={12}>
                    <Alert severity='error'>
                      Ocurrio un error al enviar el email, por favor intentelo de nuevo mas tarde!
                    </Alert>
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </div>
  );
};
