import { Container } from '@mui/material';
import { CSSProperties } from 'react';
import styles from './Seccion.module.css';

export const Seccion = ({
  children,
  className,
  id,
  sx,
}: {
  children: any;
  className?: string;
  id?: string;
  sx?: CSSProperties;
}) => {
  return (
    <section style={sx} id={id} className={`${styles.seccion} ${className}`}>
      <Container maxWidth='xl'>{children}</Container>
    </section>
  );
};
