import { Requestor } from '../../models/Requestor';

export const list =
  (requestor: Requestor) =>
  async (filters: any = {}) => {
    try {
      const { data } = await requestor.makeRequest.get(`?${new URLSearchParams(filters).toString()}`);
      return data;
    } catch (e) {
      throw { success: false, error: e };
    }
  };
