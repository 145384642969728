import { Requestor } from '../../models/Requestor';

export const get = (requestor: Requestor) => async (id: string) => {
  try {
    const { data } = await requestor.makeRequest.get(id);
    return data;
  } catch (e) {
    throw { success: false, error: e };
  }
};
