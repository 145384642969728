import styles from './Botones.module.css';

export const Boton = ({ children, onClick, href }: any) => {
  if (href) {
    return (
      <a href={href} target='_blank' className={styles.buttonwithicons}>
        {children}
      </a>
    );
  }
  return (
    <button className={styles.buttonwithicons} onClick={onClick}>
      {children}
    </button>
  );
};
