import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

type Validator = (isCreate: boolean, body: any) => boolean;

export class Requestor {
  makeRequest: AxiosInstance;
  validateWith?: Validator;

  constructor(path: string, validator?: Validator) {
    this.makeRequest = axios.create({ baseURL: `${process.env.REACT_APP_BACK_END_URL}/${path}` });
    this.validateWith = validator;
    this.makeRequest.interceptors.request.use((requestConfig: AxiosRequestConfig) => {
      requestConfig.headers = { ...requestConfig.headers, Authorization: `ApiKey ${process.env.REACT_APP_API_KEY}` };
      return requestConfig;
    });
    if (process.env.NODE_ENV === 'development') {
      this.makeRequest.interceptors.response.use(
        (response: AxiosResponse) => {
          console.log(
            `${new Date().toISOString()} [INFO] --- ${response.request.host} ${response.status} ${JSON.stringify(
              response.data
            )}`
          );
          return response;
        },
        (response: AxiosResponse) => {
          console.error(
            `${new Date().toISOString()} [ERROR] --- ${response.request.host} ${response.status} ${JSON.stringify(
              response.data
            )}`
          );
          return Promise.reject(response);
        }
      );
    }
  }

  public get validate() {
    return {
      create: (body: any) => this.validateWith?.(true, body) ?? true,
      edit: (body: any) => this.validateWith?.(false, body) ?? true,
    };
  }
}
