import { BrowserRouter, Routes as ReactRoutes, Route } from 'react-router-dom';
import App from './App';
import { Articulo } from './components/Articulo/Articulo';
import { PaginaDeArticulos } from './components/paginaDeArticulos/PaginaDeArticulos';

const basename = process.env.REACT_APP_BASENAME ?? '/';

export const Routes = () => {
  return (
    <BrowserRouter basename={basename}>
      <ReactRoutes>
        <Route path='/' element={<App />}></Route>
        <Route path='/news' element={<PaginaDeArticulos />}></Route>
        <Route path='/news/:newsId/*' element={<Articulo />}></Route>
      </ReactRoutes>
    </BrowserRouter>
  );
};
