import { useBackend } from '../useBackend';
import { slateToHtml } from '@slate-serializers/html';

export const deserialize = (content: string) => {
  try {
    const deserializedContent = JSON.parse(content);
    if(Array.isArray(deserializedContent)) return slateToHtml(deserializedContent);
    return deserializedContent;
  } catch (e) {
    return content;
  }
}

export const useArticles = () => {
  const { endpoints } = useBackend('articles');
  return { ...endpoints, deserialize };
};
