import { useEffect, useState } from 'react';
import './App.css';
import { Seccion } from './components/seccion/Seccion';
import '@fontsource/archivo-black';
import '@fontsource/open-sans';
import { Delegacion } from './components/delegacion/Delegacion';
import { Footer } from './components/footer/Footer';
import { Nav } from './components/nav/Nav';
import { Convenios } from './components/convenios/Convenios';
import { Carrusel } from './components/carrusel/Carrusel';
import { Formulario } from './components/formulario/Formulario';
import { Container } from '@mui/material';
import { useBranches } from './hooks/useBranches/useBranches';
import { useExternalBenefits } from './hooks/useExternalBenefits/useExternalBenefits';
import { ArticuloHomePage } from './components/articuloHomePage/ArticuloHomePage';

const delegados = [
  ['Nazareno Barcelo', 'Secretario General'],
  ['Andrea Viera', 'Secretaria de Actas'],
  ['Dario Roberts', 'Secretario Gremial'],
  ['Diego Flores', 'Secretario Adjunto'],
  ['Marilin Soto', 'Secretaria de Prensa'],
  ['Matias Calvo', 'Secretario de Hacienda'],
  ['Eva Garcia', 'Secretaria de Capacitacion'],
  ['Emmanuel Bravo Gonzalez', 'Comision Revisora de Cuentas'],
  ['Franco Calfupan', 'Comision Revisora de Cuentas'],
  ['Victoria Payne', 'Comision Revisora de Cuentas'],
  ['Yanina Torres', 'Comision Revisora de Cuentas'],
  ['Yalila Duran', 'Primera Vocal Titular'],
  ['Omar Castro', 'Segundo Vocal Titular'],
  ['Sergio Moraga', 'Tercer Vocal Titular'],
  ['Nerina Villavicencio', 'Primer Vocal Suplente'],
  ['Javier Alvarez', 'Segundo Vocal Suplente'],
  ['Gabriela Monti', 'Tercera Vocal Suplente'],
];

function App() {
  const { list: listBranches } = useBranches();
  const { list: listDiscounts } = useExternalBenefits();
  const [branches, setBranches] = useState([]);
  const [discounts, setDiscounts] = useState([]);

  useEffect(() => {
    listBranches().then(({ data }) => {
      setBranches(data);
    });
    listDiscounts().then(({ data }) => {
      setDiscounts(data);
    });
  }, []);

  return (
    <>
      <Nav />
      <Seccion className='homesection' id='inicio' sx={{ backgroundImage: "url('img/landing.jpg')" }}>
        <div className='hometext'>
          <h1>EL GREMIO QUE CRECE</h1>
        </div>
      </Seccion>
      <Seccion className='wp-contact'>
        <div>
          <a
            className='whatsapp-link'
            href='https://api.whatsapp.com/send?phone=92804211126&text=Hola%20APISSYS!%20Necesito%20asesoramiento%20por'
          >
            <img alt='Comunicate via whatsapp' src={`${process.env.PUBLIC_URL}/img/wp-logo.png`} />
            <div className='content'>
              <span className='link-title'>APISSYS Escucha</span>
              <span className='link-description'>
                Comunicate con nosotros si sufrís violencia laboral o necesitas asesoramiento legal urgente!
              </span>
            </div>
          </a>
        </div>
        <div>
          <a
            className='whatsapp-link'
            href='https://api.whatsapp.com/send?phone=92804211126&text=Hola%20APISSYS!%20Necesito%20asesoramiento%20por'
          >
            <img alt='Comunicate via whatsapp' src={`${process.env.PUBLIC_URL}/img/wp-logo.png`} />
            <div className='content'>
              <span className='link-title'>APISSYS Informa</span>
              <span className='link-description'>Para dudas o consultas, escribinos</span>
            </div>
          </a>
        </div>
      </Seccion>
      <Seccion id='novedades' sx={{ backgroundColor: '#77B5E4', padding: '10rem 0', textAlign: 'center' }}>
        <h2 className='newstittle'>NOVEDADES</h2>
        <div className='newscontainer'>
          <ArticuloHomePage limit={6}></ArticuloHomePage>
        </div>
        <a
          href='/news'
          style={{
            textDecoration: 'none',
            color: 'white',
            backgroundColor: 'var(--main-color)',
            padding: '.25rem .75rem',
            textAlign: 'center',
            cursor: 'pointer',
            marginTop: '2rem',
            display: 'inline-block',
          }}
        >
          Ver todas
        </a>
      </Seccion>
      <Seccion id='convenios' sx={{ padding: '10rem 0', backgroundColor: 'white' }}>
        <h2 className='agreementstittle'>
          CONVENIOS <span className='agreementssubtittle'>#BENEFICIOSAPISSYS</span>
        </h2>
        <p className='agreementstext'>
          ¿Sabías que por ser afiliado tenés descuentos en{' '}
          <strong>Hotelería, Excursiones, Gastronomía, Juegueterías, Librerías, Belleza...</strong> y muchísimo más?
        </p>
        <div className='agreementscontainer'>
          {discounts.map((discount: any) => {
            return <Convenios key={discount._id} discount={discount}></Convenios>;
          })}
        </div>
      </Seccion>
      <Seccion id='conocenos' sx={{ padding: '10rem 0' }}>
        <Container className='quienes-somos-container'>
          <div className='flexcontainer'>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h2 className='aboutustittleone'>QUIÉNES SOMOS</h2>
              <div className='logocontainer'>
                <img className='aboutuslogo' src='img/logoapissys.svg' alt='logo' />
              </div>
            </div>
            <p className='aboutustext'>
              La Asociación Personal del Instituto de Seguridad Social y Seguros tiene como objetivo primordial defender
              los derechos del trabajador. Nuestro sindicato surge para poner fin a los atropellos y a la violencia
              institucional.
              <br />
              <br />
              Somos un gremio joven, con las herramientas necesarias para crecer en pos de mejorar las condiciones de
              vida de nuestros afiliados. Para ello, brindamos asesoramiento legal calificado para dar respuesta en el
              campo laboral y también acompañamos el desarrollo estudiantil de sus familias colaborando en cada inicio
              del ciclo lectivo a través de material escolar y subsidios económicos. Además, generamos convenios para
              alcanzar beneficios comerciales, turísticos y profesionales para quienes estén agremiados.
              <br />
              <br />
              ¡Afiliate!
            </p>
          </div>
          <div className='carrusel'>
            <Carrusel></Carrusel>
          </div>
        </Container>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
            maxWidth: '1000px',
            gap: '2rem',
            margin: '0 auto',
            marginTop: '3rem',
          }}
        >
          {delegados.map(([name, position]) => {
            return (
              <div style={{ textAlign: 'center' }}>
                <img
                  style={{ borderRadius: '100%', overflow: 'hidden', width: '100%', marginBottom: '1rem' }}
                  src={`${process.env.PUBLIC_URL}/img/delegados/${name} - ${position}.jpg`}
                  alt={`${name} - ${position}`}
                />
                <h6 style={{ margin: 0 }}>{name}</h6>
                <p>{position}</p>
              </div>
            );
          })}
        </div>
      </Seccion>
      <Seccion id='delegaciones' sx={{ padding: '10rem 0', backgroundColor: '#7ED957' }}>
        <h2 className='delegationstittle'>DELEGACIONES</h2>
        <div className='delegationscontainer'>
          {branches.map((branch: any) => {
            return <Delegacion key={branch._id} delegation={branch} />;
          })}
        </div>
      </Seccion>
      <Seccion id='contacto' sx={{ padding: '10rem 0' }}>
        <Formulario></Formulario>
      </Seccion>
      <Footer />
    </>
  );
}

export default App;
