import { AccessTime, FmdGood } from '@mui/icons-material';
import { getPublicUrl } from '../../hooks/useDocuments/useDocuments';
import styles from './Delegacion.module.css';

export const Delegacion = ({ delegation }: any) => {
  const {
    name: { es: delegationName },
    times,
    address,
    img,
  } = delegation;
  return (
    <div className={styles.delegationsitem}>
      <img className={styles.delegationspic} src={getPublicUrl(img)} alt={delegationName} />
      <div className={styles.delegationtest}>
        <p className={styles['delegation-title']}>{delegationName}</p>
        {address && (
          <div className={styles['delegation-info-item']}>
            <FmdGood />
            <p>{address}</p>
          </div>
        )}
        {times && (
          <div className={styles['delegation-info-item']}>
            <AccessTime />
            <p>{times}</p>
          </div>
        )}
      </div>
    </div>
  );
};
