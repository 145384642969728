import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import styles from './Nav.module.css';
import { useState } from 'react';
import { Menu as MenuIcon } from '@mui/icons-material';

const pages = [
  ['Conocenos', '/#conocenos'],
  ['Novedades', '/#novedades'],
  ['Delegaciones', '/#delegaciones'],
  ['Contacto', '/#contacto'],
];

export const Nav = () => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position='sticky' sx={{ backgroundColor: 'white', justifyContent: 'space-between', top: 0 }}>
      <Container maxWidth='xl'>
        <Toolbar disableGutters sx={{ width: '100%', justifyContent: 'space-between' }}>
          <Typography
            variant='h6'
            noWrap
            component='a'
            href='/'
            sx={{
              mr: 2,
              display: 'flex',
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <img
              className='logoytexto'
              src={process.env.PUBLIC_URL + '/img/logoytextoapissys.svg'}
              alt='logoytextoapissys'
            />
          </Typography>

          <Box sx={{ ml: 'auto', justifyContent: 'flex-end', gap: '1rem', display: { xs: 'none', md: 'flex' } }}>
            {pages.map(([page, link]) => (
              <Typography
                variant='body1'
                noWrap
                component='a'
                href={link}
                className={styles['menu-item']}
                sx={{
                  fontWeight: 500,
                  textDecoration: 'none',
                }}
              >
                {page}
              </Typography>
            ))}
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size='large'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleOpenNavMenu}
              sx={{ color: 'var(--main-color)' }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map(([page, link]) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography
                    noWrap
                    component='a'
                    href={link}
                    className={styles['menu-item']}
                    sx={{
                      mr: 2,
                      display: { xs: 'flex', md: 'none' },
                      flexGrow: 1,
                      fontFamily: 'monospace',
                      fontWeight: 700,
                      letterSpacing: '.3rem',
                      color: 'inherit',
                      textDecoration: 'none',
                    }}
                  >
                    {page}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
