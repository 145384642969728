import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useArticles } from '../../hooks/useArticles/useArticles';
import { useDocuments } from '../../hooks/useDocuments/useDocuments';
import styles from './ArticuloHomePage.module.css';
import { useNavigate } from 'react-router-dom';

export const ArticuloHomePage = ({ limit }: any) => {
  const { list } = useArticles();
  const { getPublicUrl, getTitle, getDescription } = useDocuments();
  const [articles, setArticles] = useState<any[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    list({ state: 'PUBLISHED' }).then(({ data: a }) => {
      setArticles(a);
    });
  }, []);

  const redirectTo = (a: any) => navigate(`/news/${a._id}/${a.name.es.replace(' ', '-')}`);

  return (
    <>
      {articles
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
        .filter((_, i) => i < (limit ?? Infinity))
        .map((a) => {
          const mainDoc = a.portrait ?? a.mainImage;
          return (
            <div className={styles.newsitem} key={a._id} title={a.name.es} onClick={() => redirectTo(a)}>
              <img
                className={styles.newspic}
                src={getPublicUrl(mainDoc)}
                alt={`${getTitle(mainDoc)} - ${getDescription(mainDoc)}`}
              />
              <div className={styles.newsdesc}>
                <header>
                  <p>
                    <span style={{ marginRight: '.35rem' }}>
                      <FontAwesomeIcon icon={faClock}></FontAwesomeIcon>
                    </span>
                    {new Date(a.createdAt).toLocaleString()}
                  </p>
                  <p>{a.ca}</p>
                </header>
                <h3 className={styles.newsTitle}>{a.name.es}</h3>
                <p>{a.intro.es}</p>
              </div>
            </div>
          );
        })}
    </>
  );
};
