import { getPublicUrl } from '../../hooks/useDocuments/useDocuments';
import styles from './Convenios.module.css';

export const Convenios = ({ discount }: any) => {
  const {
    name: { es: name },
    category: { es: category },
    description: { es: description },
    img: image,
  } = discount;
  return (
    <div className={styles.agreementsitem}>
      {image && (
        <div className={styles.agreementsimg}>
          <img src={getPublicUrl(image)} />
        </div>
      )}
      <div className={styles.agreementscontent}>
        <p className={styles.comment}>{name}</p>
        <div className={styles.description}>
          <p>{description}</p>
          <p>{category}</p>
        </div>
      </div>
    </div>
  );
};
