import { useMemo } from 'react';
import { useBackend } from '../useBackend';

export const useContact = () => {
  const { endpoints } = useBackend('leads/64d419f5c432d32accb45ff4');
  return useMemo(
    () => ({
      ...endpoints,
    }),
    []
  );
};
