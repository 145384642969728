import { useMemo } from 'react';
import { useBackend } from '../useBackend';

export const getPublicUrl = (document: any) => `${process.env.REACT_APP_BACK_END_PUBLIC_URL}/${document.file.filename}`;
export const getDescription = (document: any) => document.description.es;
export const getTitle = (document: any) => document.name.es;

export const useDocuments = () => {
  const { endpoints } = useBackend('documents');
  return useMemo(
    () => ({
      ...endpoints,
      getPublicUrl,
      getDescription,
      getTitle,
    }),
    []
  );
};
