import { useMemo } from 'react';
import { list } from './list';
import { get } from './get';
import { Requestor } from '../../models/Requestor';
import { post } from './post';

export const useBackend = (path: string) => {
  const requestor = new Requestor(path);
  return useMemo(
    () => ({
      requestor,
      endpoints: {
        list: list(requestor),
        get: get(requestor),
        create: post(requestor),
      },
    }),
    []
  );
};
