import { Requestor } from '../../models/Requestor';

export const post = (requestor: Requestor) => async (data: any) => {
  try {
    const { data: result } = await requestor.makeRequest.post('/', data);
    return result;
  } catch (e) {
    throw { success: false, error: e };
  }
};
