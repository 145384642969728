import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useArticles } from '../../hooks/useArticles/useArticles';
import { useDocuments } from '../../hooks/useDocuments/useDocuments';
import styles from './PaginaDeArticulos.module.css';
import { useNavigate } from 'react-router-dom';
import { Nav } from '../nav/Nav';
import { Footer } from '../footer/Footer';
import styled from '@emotion/styled';
import { CircularProgress, Divider, Pagination } from '@mui/material';

const ArticleList = styled.article`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 2rem;
`;

const CenteredContent = styled.article`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
`;

export const PaginaDeArticulos = ({ limit, articlesPerPage = 3 }: any) => {
  const { list } = useArticles();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const { getPublicUrl, getTitle, getDescription } = useDocuments();
  const [articles, setArticles] = useState<any[]>([]);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    list({ state: 'PUBLISHED' })
      .then(({ data: a }) => {
        setArticles(a);
      })
      .catch(() => {
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const redirectTo = (a: any) => navigate(`/news/${a._id}/${a.name.es.replace(' ', '-')}`);

  return (
    <>
      <Nav />
      {isError ? (
        <CenteredContent>
          <img
            className='logoytexto'
            src={process.env.PUBLIC_URL + '/img/logoytextoapissys.svg'}
            alt='logoytextoapissys'
          />
          <span>Ha ocurrido un error inesperado y no se encuentra la pagina.</span>
          <a href='/'>Volver a inicio</a>
        </CenteredContent>
      ) : (
        <ArticleList>
          <header>
            <h1 style={{ color: 'var(--main-color)' }}>Novedades</h1>
          </header>
          {isLoading ? (
            <CenteredContent>
              <CircularProgress />
            </CenteredContent>
          ) : (
            <>
              {articles
                .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
                .filter(
                  (_, i) =>
                    i < (limit ?? Infinity) &&
                    i >= (currentPage - 1) * articlesPerPage &&
                    i < currentPage * articlesPerPage
                )
                .map((a) => {
                  const mainDoc = a.portrait ?? a.mainImage;
                  return (
                    <>
                      <div className={styles.newsitem} key={a._id} title={a.name.es} onClick={() => redirectTo(a)}>
                        <img
                          className={styles.newspic}
                          src={getPublicUrl(mainDoc)}
                          alt={`${getTitle(mainDoc)} - ${getDescription(mainDoc)}`}
                        />
                        <div className={styles.newsdesc}>
                          <header>
                            <p>
                              <span style={{ marginRight: '.35rem' }}>
                                <FontAwesomeIcon icon={faClock}></FontAwesomeIcon>
                              </span>
                              {new Date(a.createdAt).toLocaleString()}
                            </p>
                            <p>{a.ca}</p>
                          </header>
                          <h3 className={styles.newsTitle}>{a.name.es}</h3>
                          <p>{a.intro.es}</p>
                        </div>
                      </div>
                      <Divider style={{ borderColor: '#444', margin: '1rem 0' }} />
                    </>
                  );
                })}
              {articles?.length > articlesPerPage && (
                <Pagination
                  count={Math.ceil(articles.length / articlesPerPage)}
                  onChange={(_, pageNumber) => setCurrentPage(pageNumber)}
                  showFirstButton
                  showLastButton
                  sx={{ margin: '0 auto' }}
                />
              )}
            </>
          )}
        </ArticleList>
      )}
      <Footer />
    </>
  );
};
